/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../components/style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faYoutubeSquare, faInstagramSquare} from "@fortawesome/free-brands-svg-icons";
import {fas, faPlus} from "@fortawesome/free-solid-svg-icons";
library.add(fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faYoutubeSquare, faInstagramSquare,fas, faPlus);

const Publication = ({ children, detailStatus, keypub  }) => {
  let idportfolio = `#port`+children.id;
  let idportfolio2 = `port`+children.id;
  let src = null;
  let description ="";
  let title ="";
  if(children.relationships.field_image_d &&children.relationships.field_image_d !== null  ){
    src =`https://grandest.data4citizen.com/${children.relationships.field_image_d.uri.url}`;
  }
  if(children.relationships.field_image_g &&children.relationships.field_image_g.length > 0  && children.relationships.field_image_g[0].uri != null){
    src =`https://grandest.data4citizen.com/${children.relationships.field_image_g[0].uri.url}`;
  }
  else if(src == null) {
    src="/images/1.jpg";
  }
  if(children.field_texte_entete_img_g ) {
    title = children.field_texte_entete_img_g.value;
  }
  if(children.field_texte_g ){
    description = children.field_texte_g.value;
  }
  if(children.field_txt) {
    description = children.field_txt[0].value;
  }
  if(children.field_texte_entete ){
    title = children.field_texte_entete.value;
  }
  if(keypub%3 == 0 ) {
    src="/images/about-plan.jpg";
  }
  if(keypub%3 == 1){
    src="/images/about-mission.jpg";
  }
  if(keypub%3 == 2){
    src="/images/about-vision.jpg";
  }
  if(detailStatus === "true" ) {
    return (
      <>
      
      <div class="col-lg-4 col-sm-6 mb-4">
                        <div class="portfolio-item">
                            <a class="portfolio-link" data-toggle="modal" href={idportfolio}>
                                <div class="portfolio-hover">
                                    <div class="portfolio-hover-content"><i class="fas fa-plus fa-3x"></i></div>
                                </div>
                                <img class="img-fluid img-fluid-size" src={src} alt=""  style={{width: 267+"px !important" ,height: 267 +"px!important"}}/>
                            </a>
                            <div class="portfolio-caption">
                                <div class="portfolio-caption-heading "><p className="autoShowHidepub" style={{ textDecoration: "none"}}>{title}</p></div>
                            </div>
                        </div>
                    </div>
            <div className="portfolio-modal modal fade" id={idportfolio2} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src="/images/close-icon.svg" alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                   
                                    <h3 className="text-uppercase ">{title}</h3>
                                    <p className="item-intro text-muted">{children.created}</p>
                                    <img className="img-fluid d-block mx-auto imgblog" src={src} alt="" width="574px"/>
                                    <div dangerouslySetInnerHTML={{ __html: description }}></div> 
                                    
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
  }else {
    return (
      <>
      
        <div className="col-lg-4">
                        <div className="team-member">
                        <a className="portfolio-link" data-toggle="modal" href={idportfolio}>
                                <div className="portfolio-hover">
                                    <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                                </div>
                                
                                <img className="mx-auto rounded-circle rounded-cricle-img" src={src} alt="" />
                                </a>       
                           
                            <p className="text-muted autoShowHide">{title}</p>
                                {/* <a className="btn btn-dark mx-2" data-toggle="modal" href={idportfolio}>Consulter</a> */}
                            
                            
                            

                        </div>
                    </div>
            <div className="portfolio-modal modal fade" id={idportfolio2} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="close-modal" data-dismiss="modal"><img src="/images/close-icon.svg" alt="Close modal" /></div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="modal-body">
                                   
                                    <h3 className="text-uppercase">{title}</h3>
                                    <p className="item-intro text-muted">{children.created}</p>
                                    <img className="img-fluid d-block mx-auto imgblog" src={src} alt="" width="574px"/>
                                    <div dangerouslySetInnerHTML={{ __html: description }}></div> 
                                    
                                    <button className="btn btn-primary" data-dismiss="modal" type="button">
                                        <i className="fas fa-times mr-1"></i>
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
  }

    

  
 


}

Publication.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Publication
