import React, { Component } from "react"
import  {useEffect } from "react"
import { useState } from "react"; 
import Layout from "../components/layout"
import Actualite from "../components/actualite"
import Publication from "../components/publication"
import scrollTo from 'gatsby-plugin-smoothscroll';
import Scrollspy from 'react-scrollspy'
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { graphql, useStaticQuery } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import CountUp, { startAnimation } from 'react-countup';
import {fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faYoutubeSquare, faInstagramSquare} from "@fortawesome/free-brands-svg-icons";
library.add(fab, faTwitterSquare, faFacebook, faLinkedin, faGithub, faYoutubeSquare, faInstagramSquare);




const IndexPage =  ( props ) => {
  const fetch = require("node-fetch");
  let numberdataset = "";
  const [starsCount, setStarsCount] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    fetch(`https://grandest.data4citizen.com/api/datasets/2.0/search`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setStarsCount(resultData.result["count"])
      }) // set data for the number of stars
  }, [])
  numberdataset = starsCount;
  const [reusesCount, setReusesCount] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    fetch(`https://grandest.data4citizen.com/api/datasets/reusesManagement/`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setReusesCount(resultData.nhits)
      }) // set data for the number of stars
  }, [])

   let srciframe ="";
   function filterByProducteur(event){
    let lidata = event.currentTarget;
    let tag = event.currentTarget.dataset.orga;
    let iframe = document.getElementById("marque-blanche");


    var ul = document.getElementById("list-producteur");
    var items = document.getElementsByClassName("activeli");

    for (var i = 0; i < items.length; i++) {
    
      if(items[i].getAttribute("data-orga") !== tag &&  items[i].classList.contains('activeli')) {
        items[i].classList.remove("activeli");
        
        
      }
  
    }
  
    

    if(tag ==="geo_grand_est") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheGeoGrandEst?facet.field=[%22organization%22,%22tags%22,%22theme%22,%22features%22]&rows=10&start=0&fq=organization:(geo_grand_est)";

    }
    if(tag ==="geonetwork") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheGeonetwork";

    }

    if(tag ==="datagouvfr") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheDataGouvFr?facet.field=[%22organization%22,%22tags%22,%22theme%22,%22features%22]&rows=10&start=0&fq=organization:(datagouvfr)";

    }
    if(tag ==="fluo_grandest") {
      srciframe = "https://grandest.data4citizen.com/portailFullscreen?facet.field=[%22organization%22,%22tags%22,%22theme%22,%22features%22]&rows=10&start=0&fq=organization:(fluo_grandest)";

    }
    if(tag ==="meteo_france") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheMeteoFrance";

    }
    if(tag ==="insee") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheInsee";

    }
    if(tag ==="conseil_regional_grand_est") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheConseilRegionalGrandEst";

    }
    if(tag ==="atmo_grand_est") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheAtmoGrandEst";

    }
    if(tag ==="anfr") {
      srciframe = "https://grandest.data4citizen.com/marqueBlancheAnfr";

    }
     iframe.setAttribute("src", srciframe);
    
    
  
      lidata.classList.add("activeli");
      lidata.classList.remove("itemorga");


   }


  if (typeof window !== `undefined`) {

     window.onscroll = function () {

      let currentScrollPos = window.pageYOffset;
   
      if(currentScrollPos > 500 ) {
         document.getElementById("mainNav").classList.add("navbar-shrink");
      }
      else {

         document.getElementById("mainNav").classList.remove("navbar-shrink");
      }
    
   }
  }
  
 
    const [isActive, setActive] = useState(false);
    const handleOverlay = (boolean) => setActive(boolean);
    let blogs = props.data.allParagraphActualitesImageD.edges;
    let otherblogs = props.data.allParagraphActualitesImageG.edges;
    let publications = props.data.allParagraphParagrapheTextesImagesD.edges;
    let publ = props.data.allParagraphParagrapheTextesImagesG.edges;
    let produ = props.data.allRandomUser;
    
    const listActualites = [];
    const listPublications = [];

    for (let index = 0; index < blogs.length; index++) {

      if(listActualites.length <3) {
        listActualites.push(blogs[index]);
      }
      if(listActualites.length <3) {
        listActualites.push(otherblogs[index]);
      }
    }


    for (let index = 0; index < publications.length; index++) {

      if(publications[index].node.field_paragraph_ordre_img_d != null ) {
        if(listPublications.length <3 ) {
          listPublications.push(publications[index]);
        }
      }

      if(publ[index] && publ[index].node.field_paragraph_ordre_img_g != null ) {
        if(listPublications.length <3 ) {
          listPublications.push(publ[index]);
        }
      }

    }


    return (
        
        <Layout id="page-top">
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top " id="mainNav" >
            <div className="container nav-container">
                <a className="navbar-brand js-scroll-trigger" style={{cursor:"pointer"}} href={() => scrollTo('#page-top')} onClick={() => scrollTo('#page-top')}><img src="/images/logo.png" alt="" /></a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <svg className="svg-inline--fa fa-bars fa-w-14 ml-1" ariaHidden="true" focusable="false" data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
                    <i className="fas fa-bars ml-1"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                <Scrollspy className="navbar-nav text-uppercase ml-auto" items={ ['actualites','carousel-blok', 'portfoliocss', 'publications','fleurish'] } currentClassName="is-current">
                        <li className="nav-item"><a className="nav-link js-scroll-trigger"style={{cursor:"pointer"}} href={() => scrollTo('#actualites')} onClick={() => scrollTo('#actualites')}>Actualités</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" style={{cursor:"pointer"}} href={() => scrollTo('#carousel-blok')} onClick={() => scrollTo('#carousel-blok')}>Visualiseur de données</a></li>                        
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" style={{cursor:"pointer"}} href={() => scrollTo('#portfoliocss')} onClick={() => scrollTo('#portfoliocss')}>Jeu de données</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" style={{cursor:"pointer"}} href={() => scrollTo('#publications')} onClick={() => scrollTo('#publications')}>Publications</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" style={{cursor:"pointer"}} href={() => scrollTo('#fleurish')} onClick={() => scrollTo('#fleurish')}>Graphes dynamiques</a></li>
                        <li className="nav-item"><a target ="_blank" className="nav-link js-scroll-trigger" href="https://www.geograndest.fr/portail/fr/contact">Contact</a></li>
                        <li className="nav-item"><a target ="_blank" className="nav-link js-scroll-trigger" href="https://grandest.data4citizen.com/">GE CMS</a></li>
                    
                </Scrollspy>
                   
                </div>
            </div>
        </nav>
     
        <header className="masthead">
            <div className="container">
                <div className="masthead-subheading">Bienvenue sur le site Open Data!</div>
                <div className="masthead-heading text-uppercase">GrAND EST</div>
                <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href={() => scrollTo('#about')} onClick={() => scrollTo('#about')}>A propos de nous</a>
            </div>
        </header>

    
            <SEO title="Home" />

            <section id="facts">
              <div className="container" data-aos="fade-up">

                <div className="row counters">

                  <div className="col-lg-4 col-6 text-center">
                    {/* <CountUp end={produ["distinct"].length} /> */}
                    <CountUp end={9} />
                    <p>Producteurs</p>
                  </div>

                  <div className="col-lg-4 col-6 text-center">
                    <CountUp end={starsCount} />
                    <p><a href="https://grandest.data4citizen.com/portail" target="_blank">jeu de données</a></p>
                  </div>



                  <div className="col-lg-4 col-6 text-center">
                  <CountUp end={reusesCount} />
                    <p><a href="https://grandest.data4citizen.com/reuses" target="_blank">Réutilisation</a></p>
                  </div>

                </div>


              </div>
    </section>
            <section id="about" data-aos="fade-up"
    data-aos-offset="100"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-center" className="">
                <div className="container " >
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Nos objectifs</h2>
                    <h3 className="section-subheading text-muted">GéoGrandEst vise 3 objectifs :</h3>
                </div>
                    <div className="row about-cols">

                    <div className="col-md-4" id="about-cols" data-aos="fade-up" data-aos-delay="100">
                        <div className="about-col">
                        <div className="img">
                            <img src="/images/about-mission.jpg" alt="" className="img-fluid"/>
                            <div className="icon"><i className="ion-ios-speedometer-outline"></i></div>
                        </div>
                        <h2 className="title"></h2>
                        <p>
                        Construire et animer un réseau d’acteurs dans le domaine de l’information géographique pour faciliter la coordination des actions sur le territoire, la production de données de référence harmonisées et le partage des expériences, des connaissances et des savoir-faire.
                        </p>
                        </div>
                    </div>

                    <div className="col-md-4"id="about-cols"  data-aos="fade-up" data-aos-delay="200">
                        <div className="about-col">
                        <div className="img">
                            <img src="/images/about-plan.jpg" alt="" className="img-fluid"/>
                            <div className="icon"><i className="ion-ios-list-outline"></i></div>
                        </div>
                        <h2 className="title"><a href="#"></a></h2>
                        <p>
                        Mutualiser des moyens (humains, financiers et techniques) pour mettre en œuvre des projets communs dans un souci d’économie d’échelle, d’optimisation et de rationalisation des dépenses publiques.
                        </p>
                        </div>
                    </div>

                    <div className="col-md-4" id="about-cols" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-col">
                        <div className="img">
                            <img src="/images/about-vision.jpg" alt="" className="img-fluid"/>
                            <div className="icon"><i className="ion-ios-eye-outline"></i></div>
                        </div>
                        <h2 className="title"><a href="#"></a></h2>
                        <p>
                        Faciliter l’accès à l’information géographique par l’ensemble des acteurs du territoire et le grand public, via notamment des outils et des services en ligne répondant aux obligations règlementaires en matière d’information géographique et de données publiques.
                        </p>
                        </div>
                    </div>

                    </div>

                </div>
    </section>



    <section className="page-section bg-light" id="actualites" >
           <div className="container">

               <div className="text-center">
                    <h2 className="section-heading text-uppercase">Nos actualités</h2>
                    <h3 className="section-subheading text-muted">Nos dernières actualités.</h3>
                </div>
               <div className="row">
               { 
                                
                                listActualites.map((publication, i) => {
                                 
                                   let userData = publication.node;
                                     
                                    
                                       return (
                                         <Actualite children={userData}  detailStatus ="true"/>
                                               
                                         
                                       )
                                 
                                 
                                   }
                                   
                                   
                               )

                               }

               </div>
               <div className="row">

                    <div className="col-lg-8 mx-auto text-center"><p className="large text-muted">Consulter toutes nos actualités</p>
                     <a className="btn btn-dark mx-2 btn-pub" href="/actualites/">Voir plus..</a>
                     <a className="btn btn-dark mx-2 btn-pub actualitecms" target ="_blank" href="https://grandest.data4citizen.com/actualites/" style={{width: 167 +"px !important"}}>Actualités</a>
                     </div>
                </div>
               
           </div>
       </section>


       <section className="page-section bg-light" id="carousel-blok">
       <div className="container-carousel">
       <div className="text-center">
                            <h2 className="section-heading text-uppercase" >Visualiseur de données</h2>
          </div>
            <Carousel>

            <Carousel.Item>
                <a href="https://grandest.data4citizen.com/visualisation/table/?id=4b096697-bc50-40cd-a9ee-42277c1424c3&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImNyZWF0aW9uc19kX2VudHJlcHJpc2VzX3Bhcl9zZWN0ZXVyX2RfYWN0aXZpdGVfZW5fMjAxOCIsIm9wdGlvbnMiOnsiaWQiOiI0YjA5NjY5Ny1iYzUwLTQwY2QtYTllZS00MjI3N2MxNDI0YzMifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwaWRlcndlYiIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6ImFyZGVubmVzIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiIzY2YzJhNSJ9LHsiYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwaWRlcndlYiIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6ImF1YmUiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjZmM4ZDYyIn1dLCJ4QXhpcyI6InR5cGVfZW50ciIsIm1heHBvaW50cyI6NTAsInNvcnQiOiJzZXJpZTEtMSJ9XSwidGltZXNjYWxlIjoiIiwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZX0%3D" target="_blank" >
                <iframe id="iframejeu" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=4b096697-bc50-40cd-a9ee-42277c1424c3&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImNyZWF0aW9uc19kX2VudHJlcHJpc2VzX3Bhcl9zZWN0ZXVyX2RfYWN0aXZpdGVfZW5fMjAxOCIsIm9wdGlvbnMiOnsiaWQiOiI0YjA5NjY5Ny1iYzUwLTQwY2QtYTllZS00MjI3N2MxNDI0YzMifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwaWRlcndlYiIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6ImFyZGVubmVzIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiIzBCNzJCNSJ9LHsiYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwaWRlcndlYiIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6ImF1YmUiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjNjE5RkM4In0seyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoic3BpZGVyd2ViIiwiZnVuYyI6IlNVTSIsInlBeGlzIjoibWFybmUiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjOEVCQUQ4In0seyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoic3BpZGVyd2ViIiwiZnVuYyI6IlNVTSIsInlBeGlzIjoiaGF1dGVfbWFybmUiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjQkJENUU3In0seyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoic3BpZGVyd2ViIiwiZnVuYyI6IlNVTSIsInlBeGlzIjoibWV1cnRoZV9ldF9tb3NlbGxlIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiIzhkYTBjYiJ9LHsiYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwaWRlcndlYiIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6Im1ldXNlIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiIzkzOUVDNiJ9LHsiYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwaWRlcndlYiIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6ImJhc19yaGluIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiIzY2YzJhNSJ9LHsiYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwaWRlcndlYiIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6ImhhdXRfcmhpbiIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6IiM4RkFGODkifSx7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJzcGlkZXJ3ZWIiLCJmdW5jIjoiU1VNIiwieUF4aXMiOiJ2b3NnZXMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjNjZjMmE1In1dLCJ4QXhpcyI6InR5cGVfZW50ciIsIm1heHBvaW50cyI6NTAsInNvcnQiOiJzZXJpZTEtMSJ9XSwidGltZXNjYWxlIjoiIiwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZSwic2luZ2xlQXhpcyI6ZmFsc2V9" frameBorder="0"></iframe> 
                  
                   <Carousel.Caption>
                <h4 className="text-center" style ={{color:"#005395" }}>Créations d'entreprises par secteur d'activité en 2018</h4>
                 
                </Carousel.Caption>
                </a>
              </Carousel.Item>

              <Carousel.Item>
                <a href="https://grandest.data4citizen.com/visualisation/analyze/?id=1cf064f5-0f20-4437-932f-78d3ded92af8&disjunctive.insee_com&disjunctive.nom_com&disjunctive.statut&disjunctive.nom_dept&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImFycy1hdXZlcmduZS1yaG9uZS1hbHBlcy1hbmNpZW5zLXNlY3RldXJzLXBzeWNoaWF0cmllLWluZmFudG8tanV2ZW5pbGUiLCJvcHRpb25zIjp7ImlkIjoiMWNmMDY0ZjUtMGYyMC00NDM3LTkzMmYtNzhkM2RlZDkyYWY4IiwiZGlzanVuY3RpdmUuaW5zZWVfY29tIjp0cnVlLCJkaXNqdW5jdGl2ZS5ub21fY29tIjp0cnVlLCJkaXNqdW5jdGl2ZS5zdGF0dXQiOnRydWUsImRpc2p1bmN0aXZlLm5vbV9kZXB0Ijp0cnVlfX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InBpZSIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6InBvcHVsYXRpb24iLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiJyYW5nZS1CbHVlcyIsInBvc2l0aW9uIjoiY2VudGVyIn1dLCJ4QXhpcyI6Im5vbV9jb20iLCJtYXhwb2ludHMiOjUwLCJzb3J0IjoiIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJ0aW1lc2NhbGUiOiIiLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlfQ%3D%3D" target="_blank" >
                <iframe id="iframejeu" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=1cf064f5-0f20-4437-932f-78d3ded92af8&disjunctive.insee_com&disjunctive.nom_com&disjunctive.statut&disjunctive.nom_dept&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImFycy1hdXZlcmduZS1yaG9uZS1hbHBlcy1hbmNpZW5zLXNlY3RldXJzLXBzeWNoaWF0cmllLWluZmFudG8tanV2ZW5pbGUiLCJvcHRpb25zIjp7ImlkIjoiMWNmMDY0ZjUtMGYyMC00NDM3LTkzMmYtNzhkM2RlZDkyYWY4IiwiZGlzanVuY3RpdmUuaW5zZWVfY29tIjp0cnVlLCJkaXNqdW5jdGl2ZS5ub21fY29tIjp0cnVlLCJkaXNqdW5jdGl2ZS5zdGF0dXQiOnRydWUsImRpc2p1bmN0aXZlLm5vbV9kZXB0Ijp0cnVlfX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InBpZSIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6InBvcHVsYXRpb24iLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiJyYW5nZS1CbHVlcyIsInBvc2l0aW9uIjoiY2VudGVyIn1dLCJ4QXhpcyI6Im5vbV9jb20iLCJtYXhwb2ludHMiOjUwLCJzb3J0IjoiIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJ0aW1lc2NhbGUiOiIiLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlfQ%3D%3D" frameBorder="0"></iframe>    <Carousel.Caption>
                <h4 className="text-center" style ={{color:"#005395" }}>ARS Auvergne-Rhône-Alpes - Anciens secteurs psychiatrie infanto-juvénile [antérieurs à la loi du 26 janvier 2016 ]</h4>
                 
                </Carousel.Caption>
                </a>
              </Carousel.Item>

              <Carousel.Item>
                <a href="https://grandest.data4citizen.com/visualisation/information/?id=7bda264d-edcb-4553-ba4d-cd82c6ff8d7e&disjunctive.nature_pg&disjunctive.nature&disjunctive.numero&disjunctive.importance&disjunctive.sens" target="_blank" >
                <iframe src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=7bda264d-edcb-4553-ba4d-cd82c6ff8d7e&disjunctive.nature_pg&disjunctive.nature&disjunctive.numero&disjunctive.importance&disjunctive.sens&refine.numero=D913&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6InRlc3RfYXphX19fY291Y2hlX2Rlc19yb3V0ZXNfdGVfX18xMjBfdG9ubmVzIiwib3B0aW9ucyI6eyJpZCI6IjdiZGEyNjRkLWVkY2ItNDU1My1iYTRkLWNkODJjNmZmOGQ3ZSIsImRpc2p1bmN0aXZlLm5hdHVyZV9wZyI6dHJ1ZSwiZGlzanVuY3RpdmUubmF0dXJlIjp0cnVlLCJkaXNqdW5jdGl2ZS5udW1lcm8iOnRydWUsImRpc2p1bmN0aXZlLmltcG9ydGFuY2UiOnRydWUsImRpc2p1bmN0aXZlLnNlbnMiOnRydWUsInJlZmluZS5udW1lcm8iOiJEOTEzIn19LCJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJzcGlkZXJ3ZWIiLCJmdW5jIjoiU1VNIiwieUF4aXMiOiJsYXJnZXVyIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoiIzAwNTM5NSIsInBvc2l0aW9uIjoiY2VudGVyIn1dLCJ4QXhpcyI6InNlbnMiLCJtYXhwb2ludHMiOjUwLCJzb3J0IjoiIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJ0aW1lc2NhbGUiOiIiLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlLCJzaW5nbGVBeGlzIjpmYWxzZX0%3D&location=14,0,6.25496" width="1500" height="645" frameBorder="0"></iframe>      <Carousel.Caption>
                <h4 className="text-center" style ={{color:"#005395" }}>Couche des routes TE - 120 Tonnes (54)</h4>
        
                </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a href="https://grandest.data4citizen.com/visualisation/analyze/?id=8bf60376-841d-4207-99b7-d74602eb9e19&disjunctive.denomination&disjunctive.forme_juridique&disjunctive.ville&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6InNvY2lldGVzX3JhZGllZXNfZW5fMjAyMCIsIm9wdGlvbnMiOnsiaWQiOiI4YmY2MDM3Ni04NDFkLTQyMDctOTliNy1kNzQ2MDJlYjllMTkiLCJkaXNqdW5jdGl2ZS5kZW5vbWluYXRpb24iOnRydWUsImRpc2p1bmN0aXZlLmZvcm1lX2p1cmlkaXF1ZSI6dHJ1ZSwiZGlzanVuY3RpdmUudmlsbGUiOnRydWV9fSwiY2hhcnRzIjpbeyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoicGllIiwiZnVuYyI6IkNPVU5UIiwieUF4aXMiOiJzaXJlbiIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6InJhbmdlLVB1QnUiLCJwb3NpdGlvbiI6ImNlbnRlciJ9XSwieEF4aXMiOiJmb3JtZV9qdXJpZGlxdWUiLCJtYXhwb2ludHMiOiIiLCJ0aW1lc2NhbGUiOiIiLCJzb3J0IjoiIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlLCJ0aW1lc2NhbGUiOiIifQ%3D%3D" target="_blank" >
                <iframe id="iframejeu" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=8bf60376-841d-4207-99b7-d74602eb9e19&disjunctive.forme_juridique&disjunctive.greffe&disjunctive.secteur_d_activite&disjunctive.denomination&disjunctive.code_postal&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6InNvY2lldGVzX3JhZGllZXNfZW5fMjAyMCIsIm9wdGlvbnMiOnsiaWQiOiI4YmY2MDM3Ni04NDFkLTQyMDctOTliNy1kNzQ2MDJlYjllMTkiLCJkaXNqdW5jdGl2ZS5mb3JtZV9qdXJpZGlxdWUiOnRydWUsImRpc2p1bmN0aXZlLmdyZWZmZSI6dHJ1ZSwiZGlzanVuY3RpdmUuc2VjdGV1cl9kX2FjdGl2aXRlIjp0cnVlLCJkaXNqdW5jdGl2ZS5kZW5vbWluYXRpb24iOnRydWUsImRpc2p1bmN0aXZlLmNvZGVfcG9zdGFsIjp0cnVlLCJsb2NhdGlvbiI6IjYsNDYuMzkxMTksMy45OTA2NSJ9fSwiY2hhcnRzIjpbeyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoicG9sYXIiLCJmdW5jIjoiQ09VTlQiLCJ5QXhpcyI6InNpcmVuIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoicmFuZ2UtQmx1ZXMiLCJwb3NpdGlvbiI6ImNlbnRlciJ9XSwieEF4aXMiOiJncmVmZmUiLCJtYXhwb2ludHMiOiIiLCJ0aW1lc2NhbGUiOiIiLCJzb3J0IjoiIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlLCJ0aW1lc2NhbGUiOiIifQ%3D%3D&location=6,46.39119,3.99065" frameBorder="0"></iframe>    <Carousel.Caption>
                <h4 className="text-center" style ={{color:"#005395" }}>Sociétés radiées en 2020 par ville</h4>
             
                </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a href="https://grandest.data4citizen.com/visualisation/analyze/?id=8bf60376-841d-4207-99b7-d74602eb9e19&disjunctive.forme_juridique&disjunctive.greffe&disjunctive.secteur_d_activite&disjunctive.denomination&disjunctive.code_postal&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6InNvY2lldGVzX3JhZGllZXNfZW5fMjAyMCIsIm9wdGlvbnMiOnsiaWQiOiI4YmY2MDM3Ni04NDFkLTQyMDctOTliNy1kNzQ2MDJlYjllMTkiLCJkaXNqdW5jdGl2ZS5mb3JtZV9qdXJpZGlxdWUiOnRydWUsImRpc2p1bmN0aXZlLmdyZWZmZSI6dHJ1ZSwiZGlzanVuY3RpdmUuc2VjdGV1cl9kX2FjdGl2aXRlIjp0cnVlLCJkaXNqdW5jdGl2ZS5kZW5vbWluYXRpb24iOnRydWUsImRpc2p1bmN0aXZlLmNvZGVfcG9zdGFsIjp0cnVlLCJsb2NhdGlvbiI6IjYsNDYuMzkxMTksMy45OTA2NSJ9fSwiY2hhcnRzIjpbeyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoicG9sYXIiLCJmdW5jIjoiQ09VTlQiLCJ5QXhpcyI6InNpcmVuIiwic2NpZW50aWZpY0Rpc3BsYXkiOnRydWUsImNvbG9yIjoicmFuZ2UtQmx1ZXMiLCJwb3NpdGlvbiI6ImNlbnRlciJ9XSwieEF4aXMiOiJncmVmZmUiLCJtYXhwb2ludHMiOiIiLCJ0aW1lc2NhbGUiOiIiLCJzb3J0IjoiIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlLCJ0aW1lc2NhbGUiOiIifQ%3D%3D&location=6,46.39119,3.99065" target="_blank" >
                <iframe id="iframejeu" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=8bf60376-841d-4207-99b7-d74602eb9e19&disjunctive.denomination&disjunctive.forme_juridique&disjunctive.ville&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6InNvY2lldGVzX3JhZGllZXNfZW5fMjAyMCIsIm9wdGlvbnMiOnsiaWQiOiI4YmY2MDM3Ni04NDFkLTQyMDctOTliNy1kNzQ2MDJlYjllMTkiLCJkaXNqdW5jdGl2ZS5kZW5vbWluYXRpb24iOnRydWUsImRpc2p1bmN0aXZlLmZvcm1lX2p1cmlkaXF1ZSI6dHJ1ZSwiZGlzanVuY3RpdmUudmlsbGUiOnRydWV9fSwiY2hhcnRzIjpbeyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoicGllIiwiZnVuYyI6IkNPVU5UIiwieUF4aXMiOiJzaXJlbiIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6InJhbmdlLVB1QnUiLCJwb3NpdGlvbiI6ImNlbnRlciJ9XSwieEF4aXMiOiJmb3JtZV9qdXJpZGlxdWUiLCJtYXhwb2ludHMiOiIiLCJ0aW1lc2NhbGUiOiIiLCJzb3J0IjoiIiwic2VyaWVzQnJlYWtkb3duIjoiIiwic2VyaWVzQnJlYWtkb3duVGltZXNjYWxlIjoiIn1dLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlLCJ0aW1lc2NhbGUiOiIifQ%3D%3D" frameBorder="0"></iframe>    <Carousel.Caption>
                <h4 className="text-center" style ={{color:"#005395" }}>Sociétés radiées en 2020 par forme juridique</h4>
             
                </Carousel.Caption>
                </a>
              </Carousel.Item>
              
              <Carousel.Item>
                <a href="https://grandest.data4citizen.com/visualisation/analyze/?id=e6def3f2-1434-4a72-bd4a-42aef092f2f9&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6Im5vbWJyZV9kZV9uYWlzc2FuY2VzX2V0X2RlY2VzX2dyYW5kX2VzdCIsIm9wdGlvbnMiOnsiaWQiOiJlNmRlZjNmMi0xNDM0LTRhNzItYmQ0YS00MmFlZjA5MmYyZjkifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwbGluZSIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6Im5haXNzYW5jZXMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjMjZBRDI2In0seyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoic3BsaW5lIiwiZnVuYyI6IlNVTSIsInlBeGlzIjoiZGVjZXMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjMEI3MkI1In1dLCJ4QXhpcyI6ImFubmVlIiwibWF4cG9pbnRzIjo1MCwic29ydCI6IiJ9XSwidGltZXNjYWxlIjoiIiwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZSwic2luZ2xlQXhpcyI6dHJ1ZX0%3D" target="_blank" >
                <iframe id="iframejeu" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=e6def3f2-1434-4a72-bd4a-42aef092f2f9&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6Im5vbWJyZV9kZV9uYWlzc2FuY2VzX2V0X2RlY2VzX2dyYW5kX2VzdCIsIm9wdGlvbnMiOnsiaWQiOiJlNmRlZjNmMi0xNDM0LTRhNzItYmQ0YS00MmFlZjA5MmYyZjkifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InNwbGluZSIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6Im5haXNzYW5jZXMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjMjZBRDI2In0seyJhbGlnbk1vbnRoIjp0cnVlLCJ0eXBlIjoic3BsaW5lIiwiZnVuYyI6IlNVTSIsInlBeGlzIjoiZGVjZXMiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjMEI3MkI1In1dLCJ4QXhpcyI6ImFubmVlIiwibWF4cG9pbnRzIjo1MCwic29ydCI6IiJ9XSwidGltZXNjYWxlIjoiIiwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZSwic2luZ2xlQXhpcyI6dHJ1ZX0%3D" frameBorder="0" ></iframe>
                <Carousel.Caption>
                <h4 className="text-center" style ={{color:"#005395" }}>Nombre de naissances et décès Grand Est</h4>
              
                </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
              <a href="https://grandest.data4citizen.com/visualisation/analyze/?id=bb1211e0-45d1-447b-a75e-6b86d586d018&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6Im5vbWJyZV90b3RhbF9kZV9tYXJpYWdlc19lbnJlZ2lzdHJlcyIsIm9wdGlvbnMiOnsiaWQiOiJiYjEyMTFlMC00NWQxLTQ0N2ItYTc1ZS02Yjg2ZDU4NmQwMTgifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InBpZSIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6InZhbGV1ciIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6InJhbmdlLVB1QnUiLCJwb3NpdGlvbiI6ImNlbnRlciJ9XSwieEF4aXMiOiJhbm5lZSIsIm1heHBvaW50cyI6NTAsInNvcnQiOiIiLCJzZXJpZXNCcmVha2Rvd24iOiIiLCJzZXJpZXNCcmVha2Rvd25UaW1lc2NhbGUiOiIifV0sInRpbWVzY2FsZSI6IiIsImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWV9" target="_blank" >
                <iframe id="iframejeu" frameBorder="0" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=bb1211e0-45d1-447b-a75e-6b86d586d018&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6Im5vbWJyZV90b3RhbF9kZV9tYXJpYWdlc19lbnJlZ2lzdHJlcyIsIm9wdGlvbnMiOnsiaWQiOiJiYjEyMTFlMC00NWQxLTQ0N2ItYTc1ZS02Yjg2ZDU4NmQwMTgifX0sImNoYXJ0cyI6W3siYWxpZ25Nb250aCI6dHJ1ZSwidHlwZSI6InBpZSIsImZ1bmMiOiJTVU0iLCJ5QXhpcyI6InZhbGV1ciIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6InJhbmdlLVB1QnUiLCJwb3NpdGlvbiI6ImNlbnRlciJ9XSwieEF4aXMiOiJhbm5lZSIsIm1heHBvaW50cyI6NTAsInNvcnQiOiIiLCJzZXJpZXNCcmVha2Rvd24iOiIiLCJzZXJpZXNCcmVha2Rvd25UaW1lc2NhbGUiOiIifV0sInRpbWVzY2FsZSI6IiIsImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWV9"></iframe>         
                <Carousel.Caption>
                  <h4 className="text-center" style ={{color:"#005395" }}>Nombre total de mariages enregistrés par région Grand Est&nbsp;</h4>
               
                </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
              <a href="https://grandest.data4citizen.com/visualisation/analyze/?id=1d9882c1-a15d-45f6-8990-3992e97fde4f&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImRlbWFuZGV1cnNfZF9lbXBsb2lfaW5zY3JpdHNfYV9wb2xlX2VtcGxvaV9mZW1tZXMiLCJvcHRpb25zIjp7ImlkIjoiMWQ5ODgyYzEtYTE1ZC00NWY2LTg5OTAtMzk5MmU5N2ZkZTRmIn19LCJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJhcmVhc3BsaW5lcmFuZ2UiLCJmdW5jIjoiQ09VTlQiLCJ5QXhpcyI6InZhbGV1ciIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6IiMwQjcyQjUiLCJjaGFydHMiOlt7ImZ1bmMiOiJNSU4iLCJ5QXhpcyI6InZhbGV1ciJ9LHsiZnVuYyI6Ik1BWCIsInlBeGlzIjoidmFsZXVyIn1dfV0sInhBeGlzIjoiYW5uZWUiLCJtYXhwb2ludHMiOjUwLCJzb3J0IjoiIn1dLCJ0aW1lc2NhbGUiOiIiLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlfQ%3D%3D" target="_blank" >

              <iframe id="iframejeu" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=1d9882c1-a15d-45f6-8990-3992e97fde4f&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImRlbWFuZGV1cnNfZF9lbXBsb2lfaW5zY3JpdHNfYV9wb2xlX2VtcGxvaV9mZW1tZXMiLCJvcHRpb25zIjp7ImlkIjoiMWQ5ODgyYzEtYTE1ZC00NWY2LTg5OTAtMzk5MmU5N2ZkZTRmIn19LCJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJhcmVhc3BsaW5lcmFuZ2UiLCJmdW5jIjoiQ09VTlQiLCJ5QXhpcyI6InZhbGV1ciIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6IiMwQjcyQjUiLCJjaGFydHMiOlt7ImZ1bmMiOiJNSU4iLCJ5QXhpcyI6InZhbGV1ciJ9LHsiZnVuYyI6Ik1BWCIsInlBeGlzIjoidmFsZXVyIn1dfV0sInhBeGlzIjoiYW5uZWUiLCJtYXhwb2ludHMiOjUwLCJzb3J0IjoiIn1dLCJ0aW1lc2NhbGUiOiIiLCJkaXNwbGF5TGVnZW5kIjp0cnVlLCJhbGlnbk1vbnRoIjp0cnVlfQ%3D%3D&datasetcard=false"  frameBorder="0"></iframe>    <Carousel.Caption>
                <h4 className="text-center" style ={{color:"#005395" }}>Demandeurs d'emploi inscrits à Pôle emploi Femmes</h4>
               
                </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
              <a href="https://grandest.data4citizen.com/visualisation/analyze/?id=a6d16219-5c3d-427c-8b52-14ce5442b33a&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImRlbWFuZGV1cnNfZF9lbXBsb2lfaW5zY3JpdHNfYV9wb2xlX2VtcGxvaV9ob21tZXMiLCJvcHRpb25zIjp7ImlkIjoiYTZkMTYyMTktNWMzZC00MjdjLThiNTItMTRjZTU0NDJiMzNhIn19LCJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJjb2x1bW4iLCJmdW5jIjoiU1VNIiwieUF4aXMiOiJ2YWxldXIiLCJzY2llbnRpZmljRGlzcGxheSI6dHJ1ZSwiY29sb3IiOiIjMDA1Mzk1In1dLCJ4QXhpcyI6ImFubmVlIiwibWF4cG9pbnRzIjo1MCwic29ydCI6IiJ9XSwidGltZXNjYWxlIjoiIiwiZGlzcGxheUxlZ2VuZCI6dHJ1ZSwiYWxpZ25Nb250aCI6dHJ1ZX0%3D" target="_blank" >

              <iframe id="iframejeu" src="https://grandest.data4citizen.com/visualisation/frame/analyze/?id=a6d16219-5c3d-427c-8b52-14ce5442b33a&dataChart=eyJxdWVyaWVzIjpbeyJjb25maWciOnsiZGF0YXNldCI6ImRlbWFuZGV1cnNfZF9lbXBsb2lfaW5zY3JpdHNfYV9wb2xlX2VtcGxvaV9ob21tZXMiLCJvcHRpb25zIjp7ImlkIjoiYTZkMTYyMTktNWMzZC00MjdjLThiNTItMTRjZTU0NDJiMzNhIn19LCJjaGFydHMiOlt7ImFsaWduTW9udGgiOnRydWUsInR5cGUiOiJwaWUiLCJmdW5jIjoiU1VNIiwieUF4aXMiOiJhbm5lZSIsInNjaWVudGlmaWNEaXNwbGF5Ijp0cnVlLCJjb2xvciI6InJhbmdlLVB1QnUiLCJwb3NpdGlvbiI6ImNlbnRlciJ9XSwieEF4aXMiOiJhbm5lZSIsIm1heHBvaW50cyI6NTAsInNvcnQiOiIiLCJzZXJpZXNCcmVha2Rvd24iOiIiLCJzZXJpZXNCcmVha2Rvd25UaW1lc2NhbGUiOiIifV0sInRpbWVzY2FsZSI6IiIsImRpc3BsYXlMZWdlbmQiOnRydWUsImFsaWduTW9udGgiOnRydWV9" frameBorder="0"></iframe>                          
                <Carousel.Caption>
                 <h4 className="text-center" style ={{color:"#005395" }}>Demandeurs d'emploi inscrits à Pôle emploi Hommes</h4>
             
                </Carousel.Caption>
                </a>
              </Carousel.Item>
            </Carousel>
</div>
</section>
<section id="portfoliocss" className="section-bg">
      <div className="container-dataset" data-aos="fade-up">

          <div className="text-center">
                            <h2 className="section-heading text-uppercase" >Jeu de données</h2>
                            <h3 className="section-subheading text-muted" >A propos de nos jeu de données.</h3>        
          </div>

          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className=" col-lg-12">
                <ul id="portfoliocss-flters">

                  <li className="list-group-item itemorga" id="testid" data-orga="geo_grand_est" onClick={filterByProducteur}><img id="grandestlogo" alt="geograndest" src="/images/logo.png" style={{backgroundColor:"#2878BF",width:80 +"px !important"}}/></li>
                  <li className="list-group-item itemorga" data-orga="geonetwork" onClick={filterByProducteur}><img alt="Producteur_GeoNetwork" src="https://grandest.data4citizen.com/sites/default/files/organization/GeoNetwork.png"/></li>
                  <li className="list-group-item itemorga" data-orga="datagouvfr" onClick={filterByProducteur}><img alt="datagouvfr_logo" data-entity-type="file" data-entity-uuid="125ba56f-d258-4728-9615-8c90fa9108a0" src="/images/datagouv.png"/> </li>
                  <li className="list-group-item activeli" data-orga="fluo_grandest" onClick={filterByProducteur}><img alt="Producteur_Fluo" src="https://static.data.gouv.fr/avatars/d4/f41110b9474b999a17e907cfd2706f-original.png"/></li>
                  <li className="list-group-item itemorga" data-orga="meteo_france" onClick={filterByProducteur}><img alt="Producteur_MeteoFrance" src="https://grandest.data4citizen.com/sites/default/files/organization/1200px-Logo_M%C3%A9t%C3%A9o_France_2016.svg.png"/> </li>
                  <li className="list-group-item itemorga" data-orga="insee" onClick={filterByProducteur}><img alt="insee_logo" data-entity-type="file" data-entity-uuid="bb996e72-8e0b-4f8f-a92b-eba723a75fc5" src="/images/insee.png"/> </li>
                  <li className="list-group-item itemorga" data-orga="conseil_regional_grand_est" onClick={filterByProducteur}><img alt="ge_logo" data-entity-type="file" data-entity-uuid="1305780f-dc41-43b2-980d-ac001c8c5ab2" src="/images/conseil-regional.jpg"/> </li>
                  <li className="list-group-item itemorga" data-orga="atmo_grand_est" onClick={filterByProducteur}><img alt="Producteur_Atmo" src="https://static.data.gouv.fr/avatars/48/264900ca9341ee84db60748c637aa5-original.jpg"/></li>
                  <li className="list-group-item itemorga" data-orga="anfr" onClick={filterByProducteur}><img alt="Producteur_ANFR" src="https://grandest.data4citizen.com/sites/default/files/organization/logoANFRheader3_0.png"/> </li>
              
                </ul>
              </div>
        </div>

        <div className="row portfoliocss-container" data-aos="fade-up" data-aos-delay="200">

          <div className="col-lg-12 col-md-12 portfoliocss-item filter-app">
            <div className="portfoliocss-wrap">
            <iframe stitle=" jeu de données" id="marque-blanche" className="marque_blanche" src="https://grandest.data4citizen.com/portailFullscreen?facet.field=[%22organization%22,%22tags%22,%22theme%22,%22features%22]&rows=10&start=0&fq=organization:(fluo_grandest)" width="1500" height="500"/> 
            </div>
          </div>

        </div>

      </div>
    </section>

        <section className="page-section bg-light" id="publications" >
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Nos dernières publications</h2>
                    <h3 className="section-subheading text-muted">Consulter nos dernières publications.</h3>
                </div>
                <div className="row">
                { 
                                 
                                 listPublications.map((publication, i) => {
                                    
                                    const userData = publication.node;
                                    
                                      
                            
                                        return (
                                                <Publication keypub={i} children={userData} detailStatus="false" />
                                          
                                        )
                                  
                                    }
                                    
                                    
                                )

                                }

                </div>
                <div className="row " >

                    <div className="col-lg-8 mx-auto text-center"><p className="large text-muted">Consulter toutes nos publications</p>
                     <a className="btn btn-dark mx-2 btn-pub" href="/publications/">Voir plus ..</a>
                     <a className="btn btn-dark mx-2 btn-pub actualitecms" target ="_blank" href="https://grandest.data4citizen.com/paragraph/" style={{width: 167 +"px !important"}}>Publications</a>

                     </div>
                </div>
            </div>
        </section>

        <section  id="fleurish">
  <div className="container-carousel" data-aos="fade-up">
    <div className="flourish-embed flourish-chart" data-src="visualisation/2793948">
    <div className="text-center">
                            <h2 className="section-heading text-uppercase" >Graphes dynamiques</h2>
    </div>
     </div>
  </div>
   
    </section>

        <div className="py-5 " data-aos="fade-up"
    data-aos-offset="100"
    data-aos-delay="50"
    data-aos-duration="999"
    data-aos-easing="ease-in-out"
    data-aos-mirror="true"
    data-aos-once="false"
    data-aos-anchor-placement="top-center" id="partenariat">
            <div className="container">
                <div className="row" style={{paddingTop: 10 +"px !important"}}>
                    <div className="col-md-3 col-sm-6 my-3">
                    <a href="http://www.prefectures-regions.gouv.fr/grand-est/" target="_blank" rel="noreferrer"><img style={{width:150+"px", height: 70+"px"}} className="img-fluid d-block mx-auto" src="https://www.geograndest.fr/portail/sites/default/files/logo_footer/logo_bm_reg-grand-est-sur-gge.png" alt="" /></a>
                    </div>
                    <div className="col-md-3 col-sm-6 my-3">
                        <a href="https://www.grandest.fr"><img className="img-fluid d-block mx-auto" style={{width:150+"px", height: 70+"px"}} src="https://www.geograndest.fr/portail/sites/default/files/logo_footer/grandest.png" alt="" /></a>
                    </div>
                    <div className="col-md-3 col-sm-6 my-3">
                        <a href="https://europa.eu/european-union/index_fr"><img style={{width:150+"px", height: 70+"px"}} className="img-fluid d-block mx-auto" src="https://www.geograndest.fr/portail/sites/default/files/embleme_ue_titre_bleu.png" alt="" /></a>
                    </div>
                    <div className="col-md-3 col-sm-6 my-3">
                        <a href="https://www.grandest.fr/europe-grandest" target="_blank" rel="noreferrer"><img className="img-fluid d-block mx-auto" src="https://www.geograndest.fr/portail/sites/default/files/grand_est_feder_deroule-01.jpg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>

        <section className="page-section bg-dark text-white" id="contact">
            <div className="container text-center">
                <h2 className="mb-4">FAITES LE PREMIER PAS!</h2>
                <a className="btn btn-light btn-xl" href="https://www.geograndest.fr/portail/fr/contact" target="_blank">CONTACTEZ-NOUS</a>
            </div>
        </section>
                <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-left"><strong>GrandEst</strong> © Tous droits réservés 2020</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                    <a className="btn btn-dark btn-social mx-2"  rel="noreferrer" href="https://www.facebook.com/regiongrandest" target="_blank" rel="noreferrer" title="GrandEst on Facebook"><FontAwesomeIcon width ="40" height="40" className='icon-social-media' icon={['fab', 'facebook']} /> </a>
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.instagram.com/laregiongrandest/" target="_blank" rel="noreferrer" title="GrandEst on instagram"><FontAwesomeIcon width ="40" height="40" className='icon-social-media' icon={['fab', 'instagram']} /> </a>   
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://twitter.com/regiongrandest?lang=fr" target="_blank" rel="noreferrer" title="GrandEst on Twitter"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'twitter']} /></a>
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.linkedin.com/organization-guest/company/regiongrandest?challengeId=AQGIJdTmgQRQAQAAAXMzws4rWaGVPB5eUG24az3sAMsZolpawWcG4um0fs1WTvdukzeRwSfup8kKB_fSS3_dNgn7ZQhzJSOvSw&amp;submissionId=d1e1cf2c-8f18-2016-45ae-34bbea0ab656" target="_blank" rel="noreferrer" title="GrandEst on LinkedIn"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'linkedin']} /></a>            
                    <a className="btn btn-dark btn-social mx-2" rel="noreferrer" href="https://www.youtube.com/RegionGrandEst" target="_blank" rel="noreferrer" title="GrandEst on Youtube"><FontAwesomeIcon width ="40" height="40"  className='icon-social-media' icon={['fab', 'youtube']} /></a>    
                    </div>
                    <div className="col-lg-4 text-lg-right">
                        <a className="mr-3"href="https://grandest-monitoring.data4citizen.com/" target="_blank" >Monitoring</a>
                        <a href="https://grandest.data4citizen.com/mentions_legales" target="_blank">Mentions Légales</a>
                    </div>
                </div>
            </div>
        </footer>
                    
             
          </Layout>
            );
}
   
  




export default IndexPage

export const query = graphql`
{
 
  allParagraphParagrapheTextesImagesG(sort: {fields: field_paragraph_ordre_img_g, order: ASC}) {
   edges {
    node {
      id
      field_paragraph_ordre_img_g
      created(formatString: "DD/MM/YYYY")
      field_texte_entete_img_g {
        value
      }
      field_txt {
        value
      }
      field_texte_sous_titre_d {
        value
      }
      relationships {
        field_image_g {
          uri {
            url
            value
          }
        }
      }
    }
   }

  },
  allParagraphParagrapheTextesImagesD(sort: {fields: field_paragraph_ordre_img_d, order: ASC}) {
    edges {
      node {
        id
        field_paragraph_ordre_img_d
        created(formatString: "DD/MM/YYYY")
        field_texte_entete {
          value
        }
        field_texte_g {
          value
        }
        relationships {
          field_image_d {
            uri {
              url
              value
            }
          }
        }
        
        parent_field_name
        
      }
    }
    totalCount
  },
    allParagraphActualitesImageD(sort: {order: ASC, fields: field_act_ordre_img_d}, limit: 4) {
      edges {
        node {
          id
          field_act_ordre_img_d
          created(formatString: "DD/MM/YYYY")
          field_act_entete_img_d {
            value
          }
          field_act_texte_colonne_g {
            value
          }
          field_act_texte_g {
            value
          }
          relationships {
            field_act_img_d {
              uri {
                url
                value
              }
            }
          }
        }
      }
      totalCount
    },
    allRandomUser {

      distinct(field: producteur)
    },

      allParagraphActualitesImageG(sort: {fields: field_act_ordre_img_g, order: ASC}) {
        edges {
          node {
            id
            field_act_ordre_img_g
            created
            field_act_entete_img_g {
              value
            }
            field_act_texte_d {
              value
            }
            relationships {
              field_act_img_g {
                uri {
                  url
                  value
                }
              }
            }
          }
        }
      }
      
 }
`;
